@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Add these styles in your CSS or global styles */
/* WebKit scrollbar styling */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #4a5568; /* Adjust the color to your preference */
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #cbd5e0; /* Adjust the color to your preference */
}